import type { Ref } from 'vue'

import { db, IProject, ISite } from "@/db"
import { AnemiaAssessment } from "@/models/AnemiaAssessment"
import { DevelopmentalScreening } from "@/models/DevelopmentalScreening"
import { FeedingScreening } from "@/models/Feeding"
import { GrowthAssessment } from "@/models/GrowthAssessment"
import {
  getFoldInSiteAnemiaAssessmentsFromServer
} from "@/services/AnemiaAssessment"
import * as api from "@/services/Api"
import {
  getCachedChildrenForSite,
  getChildById,
  getFoldInSiteChildrenFromServer,
} from "@/services/Child"
import {
  getFoldInSiteDevelopmentalScreeningsFromServer
} from "@/services/DevelopmentalScreening"
import {
  getFoldInSiteBestPracticeAssessmentsFromServer,
  getFoldInSiteFeedingScreeningsFromServer,
  getFoldInSiteFeedingObservationsFromServer,
  getFoldInSitePositioningObservationsFromServer,
  getFoldInSiteCupOrSpoonObservationsFromServer,
  getFoldInSiteSelfFeedingObservationsFromServer,
  mapFeedingObservationIds,
} from "@/services/Feeding"
import {
  getFoldInSiteGrowthAssessmentsFromServer
} from "@/services/GrowthAssessment"
import {
  getFoldInSiteVisitReportsFromServer
} from "@/services/SiteVisitReport"
import { handlePendingUploads } from "@/services/Upload"
import { updateLastUpdateTime } from "@/utils/GlobalState"
import { isKnown } from "@/utils/Utilities"

/**
 * Munges projects and sites back together. Returns an object with two properties:
 * projects and sites, where projects contains a property "sites" which is an array
 * of associated sites. The top-level sites prop is an array of unaffiliated sites.
 * (It is rare but conceivable that both projects and sites would contain entries.)
 *
 * @return [Object]  {projects: [proj, proj, ...], sites: [site, site, ...]}
 */
export async function getCachedProjectsAndSites() {
  const results = { projects: [], sites: [] }
  const projects = await db.projects.orderBy("name").toArray()
  const sites = await db.sites.orderBy("name").toArray()
  // Munge
  const projectsById = {}
  for (let i = 0; i < projects.length; i++) {
    const project = projects[i]
    project["sites"] = []
    projectsById[project.cmiId] = project
  }
  for (let i = 0; i < sites.length; i++) {
    const site = sites[i]
    if (site.projectId && site.projectId in projectsById) {
      projectsById[site.projectId].sites.push(site)
    } else {
      results.sites.push(site)
    }
  }
  results.projects = Object.values(projectsById)
  return results
}


/**
 * Attempts to sync pending uploads. If successful, queries server for current user's site list;
 * and updates site list in IndexedDB. Doesn't return anything.
 * Underlying API function can throw the following exceptions; they're not caught here:
 *  - CONNECTIVITY_REQUIRED
 *  - LOGIN_REQUIRED
 *  - SERVER_ERROR
 */
export async function updateSitesFromServer() {
  try {
    handlePendingUploads()
  } catch (error) {
    console.log(error)
    return
  }

  const data = await api.getProjectsAndSites()
  const { sites, projects } = data
  // Parse out the associated sites from their projects; we're inserting them
  // as projects and sites separately.
  for (let i = 0; i < data.projects.length; i++) {
    sites.push(...projects[i].associatedSites)
    delete projects[i].associatedSites
  }

  // Create a mapping of CMI ids to internal ids for what we have in the db already.
  const projectIds = {}
  const siteIds = {}
  await db.projects.toArray((results: Array<object>) => {
    results.forEach((item: { id: number, cmiId: number }) => projectIds[item.cmiId] = item.id)
  })
  await db.sites.toArray((results: Array<object>) => {
    results.forEach((item: { id: number, cmiId: number }) => siteIds[item.cmiId] = item.id)
  })

  // Handle update/insert of the API results
  const promises = []
  projects.forEach((project: IProject) => {
    if (project.cmiId in projectIds) {
      promises.push(db.projects.update(projectIds[project.cmiId], project))
    }
    else {
      promises.push(db.projects.add(project))
    }
  })
  sites.forEach((site: ISite) => {
    if (site.cmiId in siteIds) {
      promises.push(db.sites.update(siteIds[site.cmiId], site))
    }
    else {
      promises.push(db.sites.add(site))
    }
  })
  await Promise.all(promises)
  await updateLastUpdateTime({ type: "sitesAndProjects" })
}


/**
 * Return a new instance, by default from IndexedDB, but optionally from server.
 * If from server, we update the database with whatever the server returns.
 * @args {number} cmiId - the *server's* id for site
 * @return [Site]
 */
export async function getSiteByCmiId(cmiId: number, options = { fromServer: false }) {
  let site = await db.sites.get({ cmiId })
  if (options.fromServer) {
    const localId = site?.id
    site = await api.getSite(cmiId)
    site.id = localId
    await db.sites.put(site)
  }
  return site
}

export async function getSitesForProject(projectId: number) {
  return await db.sites
    .where({ projectId })
    .sortBy("name")
}

/**
 * Return true or false whether we think the current user can edit
 * the specified site. Expects an object with prop either site or cmiId.
 *
 * (Note: this is just to determine whether to show editing interface for
 * the site. It's up to the server to ultimately decided whether the user
 * has sufficient permissions.)
 * @args {object} with property site (object representing site) or cmiId
 * @return [bool]
 */
export async function canEditSite(args: { site?: object, cmiId?: number }) {
  let { site } = args
  const { cmiId } = args
  if (cmiId) {
    site = await getSiteByCmiId(cmiId)
  }
  const editingRoles = ["staff", "site administrator", "site supporter"]
  return editingRoles.includes(site["role"])
}

/**
 * Return an array (could be empty) of objects representing Accounts matching
 * the token. Token is compared to Accounts' first name, last name, and username.
 * The objects contain the following properties: name, id, username.
 *
 * @args {Number}  siteCmiId
 * @args {String}  token
 * @return [Array]
 */
export async function searchAccountsToLink(siteCmiId: number, token: string) {
  return await api.searchAccountsToLinkForSite(siteCmiId, token)
}

export async function getSiteAccountList(site: { cmiId: number }) {
  return await api.getSiteAccountList(site.cmiId)
}

export async function getTrainingModePurgeList(site: { cmiId: number }) {
  return await api.getTrainingModePurgeList(site.cmiId)
}

export async function disableTrainingMode(site: { id: number, cmiId: number }) {
  await api.disableTrainingMode(site.cmiId)
    .then(async () => {
      // Update the database with what we believed just happened.
      await db.sites.update(site.id, { inTrainingMode: false })
      // This might be better served by querying the server.
      await db.children
        .where({ siteId: site.cmiId })
        .filter(c => c.createdDuringTraining === true)
        .delete()
    })
}

export async function updateAccountRoleAtSite(accountId: number, siteId: number, role: string) {
  return await api.updateAccountRoleAtSite(accountId, siteId, role)
}

export async function removeAccountRoleFromSite(accountId: number, siteId: number) {
  return await api.removeAccountRoleFromSite(accountId, siteId)
}

export async function linkAccountToSite(accountId: number, siteId: number, role: string) {
  return await api.linkAccountToSite(accountId, siteId, role)
}

export async function createSiteAccount(siteId: number, account: object) {
  return await api.createSiteAccount(siteId, account)
}

/**
 * Save a new instance of a site or update an existing one.
 * This is an online-only function; only after the request
 * is fully processed by the backend do the results get
 * persisted locally.
 * @args {object}     site
 * @return {object}   site
 */
export async function addOrUpdateSite(data: ISite) {
  const localId = data.id
  delete data.id
  const siteFromApi = await api.uploadSite(data)
  // We don't catch any exceptions here; they're covered in the interface.
  siteFromApi.id = localId
  await db.sites.put(siteFromApi)
  return siteFromApi
}

export async function updateSiteSettings(data: { cmiId: number }) {
  const fieldNames = [
    "anemiaEnabled",
    "growthEnabled",
    "earlyidEnabled",
    "isFeedingScreeningEnabled",
    "isFeedingObservationEnabled",
    "isChildMealtimeBestPracticeAssessmentEnabled",
    "areBehaviorAndEmotionRequired",
    "inTrainingMode",
  ]
  const dataToUpload = { cmiId: data.cmiId }
  fieldNames.forEach(fieldName => { if (fieldName in data) { dataToUpload[fieldName] = data[fieldName] } })
  await api.uploadSite(dataToUpload, true)
  // We don't catch any exceptions here; they're covered in the interface.
  // Save these changes to the database
  await db.sites
    .where("cmiId")
    .equals(data.cmiId)
    .modify(dataToUpload)
}


/* This is for preparing for offline usage. It pulls down paginated lists of children
 * from the specified site who have been modified since the last time (if) this user has
 * synced with the server.
 * @args {siteId}     CMI Id for site
 * @args {options.count}     Boolean. Just return the total count of pending children?
 * @args {options.discharged}     Boolean. Return discharged children (instead of only current).
 * @args {options.page}     Int. 1-indexed page number (of the paginated results)
*/
export async function getPendingChildrenForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: discharged ? "siteChildrenDischarged" : "siteChildren", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null

  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getChildrenForSite(site.cmiId, newOptions)
  return count ? results.count : results.results
}


/* This is for preparing for offline usage. It pulls down paginated lists of growth
 * assessments for the children from the specified site which have been modified
 * since the last time (if) this user has synced with the server.
 * @args {siteId}               CMI Id for site
 * @args {options.count}        Boolean. Just return the total count of pending assessments?
 * @args {options.discharged}   Boolean. Return results for discharged children (instead of only current).
 * @args {options.page}         Int. 1-indexed page number (of the paginated results)
*/
export async function getPendingGrowthAssessmentsForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "siteGrowthAssessments", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getGrowthAssessmentsForSite(site.cmiId, newOptions)
  return count ? results.count : results.results
}

// See docs above (or below)
export async function getPendingBestPracticeAssessmentsForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "siteBestPracticeAssessments", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getBestPracticeAssessmentsForSite(site.cmiId, newOptions)
  return count ? results.count : results.results
}

/* This is for preparing for offline usage. It pulls down paginated lists of growth
 * assessments for the children from the specified site which have been modified
 * since the last time (if) this user has synced with the server.
 * @args {siteId}               CMI Id for site
 * @args {options.count}        Boolean. Just return the total count of pending assessments?
 * @args {options.discharged}   Boolean. Return results for discharged children (instead of only current).
 * @args {options.page}         Int. 1-indexed page number (of the paginated results)
*/
export async function getPendingAnemiaAssessmentsForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "siteAnemiaAssessments", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getAnemiaAssessmentsForSite(site.cmiId, newOptions)
  return count ? results.count : results.results
}

/* This is for preparing for offline usage. It pulls down paginated lists of developmental
 * screenings for the children from the specified site which have been modified
 * since the last time (if) this user has synced with the server.
 * @args {siteId}               CMI Id for site
 * @args {options.count}        Boolean. Just return the total count of pending assessments?
 * @args {options.discharged}   Boolean. Return results for discharged children (instead of only current).
 * @args {options.page}         Int. 1-indexed page number (of the paginated results)
*/
export async function getPendingDevelopmentalScreeningsForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "siteDevelopmentalScreenings", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getDevelopmentalScreeningsForSite(site.cmiId, newOptions)
  return count ? results.count : results.results
}

// See docs for functions above!
export async function getPendingFeedingScreeningsForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "feedingScreenings", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getFeedingScreeningsForSite(site.cmiId, newOptions)
  return count ? results.count : results.results
}

export async function getPendingFeedingObservationsForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "feedingObservations", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getFeedingObservationsForSite(site.cmiId, newOptions)
  return count ? results.count : results.results
}

export async function getPendingPositioningObservationsForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "positioningObservations", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getPositioningObservationsForSite(site.cmiId, newOptions)
  if (isKnown(results.count)) {
    return results.count
  }
  const observations = results.results
  mapFeedingObservationIds(observations)
  return observations
}

export async function getPendingCupOrSpoonObservationsForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "cupOrSpoonObservations", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getCupOrSpoonObservationsForSite(site.cmiId, newOptions)
  if (isKnown(results.count)) {
    return results.count
  }
  const observations = results.results
  mapFeedingObservationIds(observations)
  return observations
}

export async function getPendingSelfFeedingObservationsForSite(site: ISite, options: { count?: boolean, discharged?: boolean, page?: number }) {
  const { count = false, discharged = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "selfFeedingObservations", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, discharged, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getSelfFeedingObservationsForSite(site.cmiId, newOptions)
  if (isKnown(results.count)) {
    return results.count
  }
  const observations = results.results
  mapFeedingObservationIds(observations)
  return observations
}

export async function getPendingSiteVisitReportsForSite(site: ISite, options: { count?: boolean, page?: number }) {
  const { count = false, page = 1 } = options
  const lastUpdate = await db.lastUpdated.get({ type: "siteVisitReports", localItemId: site.id })
  const asOf = lastUpdate ? lastUpdate.date : null
  const newOptions = { asOf, page, count, pageSize: count ? 1 : api.DEFAULT_PAGE_SIZE }
  const results = await api.getSiteVisitReportsForSite(site.cmiId, newOptions)
  const response = count ? results.count : results.results
  return response
}

interface ReferralResults {
  growth: Array<GrowthAssessment>
  anemia: Array<AnemiaAssessment>
  developmentalScreening: Array<DevelopmentalScreening>
}

// Return ReferralResults as specified for the given site: arrays of assessments, not referrals per se.
export async function getCurrentReferralsForSite(site: ISite): Promise<ReferralResults> {

  const currentChildrenIds = new Set(
    (await getCachedChildrenForSite(site.cmiId)).map(c => c.id)
  )

  async function getMostRecentAssessmentPerChild(tableName: string) {
    const allAssessments = await db[tableName]
      .where({ siteId: site.cmiId })
      .reverse()
      .sortBy("dateOfAssessment")
    const childrenAlreadySeen = new Set()
    const results = []
    // Only extract the first assessment we find for each *current* child.
    allAssessments.forEach((a: { childId: number }) => {
      if (currentChildrenIds.has(a.childId) && !childrenAlreadySeen.has(a.childId)) {
        results.push(a)
        childrenAlreadySeen.add(a.childId)
      }
    })
    return results

  }
  const results = {
    growth: [],
    anemia: [],
    developmentalScreening: [],
    feedingScreening: [],
  }

  if (site.growthEnabled) {
    const assessments = await getMostRecentAssessmentPerChild("growthAssessments")
    results["growth"] = await Promise.all(assessments
      .filter(a => a.hasReferrals)
      .map(async a => {
        const child = await getChildById(a.childId)
        const asModel = new GrowthAssessment(child, a)
        await asModel.processAssessment()
        return asModel
      }))
  }

  if (site.isFeedingScreeningEnabled) {
    const assessments = await getMostRecentAssessmentPerChild("feedingScreenings")
    results["feedingScreening"] = await Promise.all(assessments
      .filter(a => a.hasReferrals)
      .map(async a => {
        const child = await getChildById(a.childId)
        return new FeedingScreening(child, a)
      }))
  }

  if (site.anemiaEnabled) {
    const assessments = await getMostRecentAssessmentPerChild("anemiaAssessments")
    results["anemia"] = await Promise.all(assessments
      .filter(a => a.hasReferrals)
      .map(async a => {
        const child = await getChildById(a.childId)
        const asModel = new AnemiaAssessment(child, a)
        await asModel.processAssessment()
        return asModel
      }))
  }

  if (site.earlyidEnabled) {
    const assessments = await getMostRecentAssessmentPerChild("developmentalScreenings")
    results["developmentalScreening"] = await Promise.all(assessments
      .filter(a => a.hasReferrals)
      .map(async a => {
        const child = await getChildById(a.childId)
        return new DevelopmentalScreening(child, a)
      }))
  }

  return results
}

/*
 * Takes obvious args for site and url params plus a stats object: a ref of the percent of the results that have been downloaded out of a known total.
 * Based on the last time that the server was queried for children, it updates the API url, then iterates over its paginated results.
 * As it retrieves pages of data, it outsources the process of persisting them. Once done, it updates the database with the last update time for children
 * at this site.
 *
 * Doesn't return anything!
 */
export async function processPaginatedChildrenForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "siteChildren", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedChildrenForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteChildrenFromServer(site.cmiId), api.childrenResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  updateLastUpdateTime({ type: urlParams.discharged ? "siteChildrenDischarged" : "siteChildren", localItemId: site.id })
}

// See docs for processPaginatedChildrenForSite (same idea, but for growth assessments)
export async function processPaginatedGrowthAssessmentsForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "siteGrowthAssessments", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedGrowthAssessmentsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteGrowthAssessmentsFromServer(site.cmiId), api.assessmentResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "siteGrowthAssessments", localItemId: site.id })
  await db.children
    .where({ siteId: site.cmiId })
    .toArray(children => children.forEach(child => updateLastUpdateTime({ type: "childAssessments", localItemId: child.id })))
}

export async function processPaginatedBestPracticeAssessmentsForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "siteBestPracticeAssessments", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedBestPracticeAssessmentsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteBestPracticeAssessmentsFromServer(site.cmiId), api.assessmentResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "siteBestPracticeAssessments", localItemId: site.id })
  await db.children
    .where({ siteId: site.cmiId })
    .toArray(children => children.forEach(child => updateLastUpdateTime({ type: "childAssessments", localItemId: child.id })))
}

// See docs for processPaginatedChildrenForSite (same idea, but for feeding screenings)
export async function processPaginatedFeedingScreeningsForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "feedingScreenings", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedFeedingScreeningsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteFeedingScreeningsFromServer(site.cmiId), api.assessmentResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "feedingScreenings", localItemId: site.id })
  await db.children
    .where({ siteId: site.cmiId })
    .toArray(children => children.forEach(child => updateLastUpdateTime({ type: "childAssessments", localItemId: child.id })))
}

export async function processPaginatedFeedingObservationsForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "feedingObservations", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedFeedingObservationsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteFeedingObservationsFromServer(site.cmiId), api.assessmentResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "feedingObservations", localItemId: site.id })
  await db.children
    .where({ siteId: site.cmiId })
    .toArray(children => children.forEach(child => updateLastUpdateTime({ type: "childAssessments", localItemId: child.id })))
}

export async function processPaginatedPositioningObservationsForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "positioningObservations", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedPositioningObservationsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSitePositioningObservationsFromServer(site.cmiId), api.assessmentResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "positioningObservations", localItemId: site.id })
  await db.children
    .where({ siteId: site.cmiId })
    .toArray(children => children.forEach(child => updateLastUpdateTime({ type: "childAssessments", localItemId: child.id })))
}

export async function processPaginatedCupOrSpoonObservationsForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "cupOrSpoonObservations", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedCupOrSpoonObservationsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteCupOrSpoonObservationsFromServer(site.cmiId), api.assessmentResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "cupOrSpoonObservations", localItemId: site.id })
  await db.children
    .where({ siteId: site.cmiId })
    .toArray(children => children.forEach(child => updateLastUpdateTime({ type: "childAssessments", localItemId: child.id })))
}

export async function processPaginatedSelfFeedingObservationsForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "selfFeedingObservations", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedSelfFeedingObservationsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteSelfFeedingObservationsFromServer(site.cmiId), api.assessmentResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "selfFeedingObservations", localItemId: site.id })
  await db.children
    .where({ siteId: site.cmiId })
    .toArray(children => children.forEach(child => updateLastUpdateTime({ type: "childAssessments", localItemId: child.id })))
}

// See docs for processPaginatedChildrenForSite (same idea, but for anemia assessments)
export async function processPaginatedAnemiaAssessmentsForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "siteAnemiaAssessments", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedAnemiaAssessmentsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteAnemiaAssessmentsFromServer(site.cmiId), api.assessmentResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "siteAnemiaAssessments", localItemId: site.id })
  await db.children
    .where({ siteId: site.cmiId })
    .toArray(children => children.forEach(child => updateLastUpdateTime({ type: "childAssessments", localItemId: child.id })))
}

// See docs for processPaginatedChildrenForSite (same idea, but for growth assessments)
export async function processPaginatedDevelopmentalScreeningsForSite(site: ISite, urlParams: { discharged?: boolean }, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "siteDevelopmentalScreenings", localItemId: site.id })
  urlParams["asOf"] = lastUpdate ? lastUpdate.date.toISOString() : null
  const url = api.getPaginatedDevelopmentalScreeningsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteDevelopmentalScreeningsFromServer(site.cmiId), api.assessmentResponseTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "siteDevelopmentalScreenings", localItemId: site.id })
  await db.children
    .where({ siteId: site.cmiId })
    .toArray(children => children.forEach(child => updateLastUpdateTime({ type: "childAssessments", localItemId: child.id })))
}

// See docs for processPaginatedChildrenForSite (same idea, but for SVRs)
export async function processPaginatedSiteVisitReportsForSite(site: ISite, stats: { percentComplete: Ref, total: number }) {
  const { percentComplete, total } = stats
  const lastUpdate = await db.lastUpdated.get({ type: "siteVisitReports", localItemId: site.id })
  const urlParams = { asOf: lastUpdate ? lastUpdate.date.toISOString() : null }
  const url = api.getPaginatedSiteVisitReportsForSiteUrl(site.cmiId, urlParams)
  for await (const count of api.stepThroughPaginatedAPI(url, getFoldInSiteVisitReportsFromServer(site.cmiId), api.siteVisitReportTransformers)) {
    percentComplete.value += 100 * count / total
  }
  await updateLastUpdateTime({ type: "siteVisitReports", localItemId: site.id })
}

// Return an array of BP types: "child" and/or "site".
export function getBestPracticeTypeCandidatesForSite(site: { typesOfServices: Array<string> }) {
  const results = []
  if (site.typesOfServices.includes("day") || site.typesOfServices.includes("residential")) {
    results.push("site")
  }
  if (site.typesOfServices.includes("periodical")) {
    results.push("child")
  }
  return results
}
