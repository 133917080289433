import { isProxy, toRaw } from "vue"
import dayjs from "dayjs"

import {
  db,
  IChild,
  IFeedingScreening,
  IFeedingObservation,
  IPositioningObservation,
  ICupOrSpoonObservation,
  IBestPracticeAssessment,
  ISelfFeedingObservation,
} from "@/db"
import { Child } from "@/models/Child"
import {
  FeedingScreening,
  FeedingObservation,
  PositioningObservation,
  CupOrSpoonObservation,
  SelfFeedingObservation,
  ChildBestPracticeAssessment,
} from "@/models/Feeding"
import * as api from "@/services/Api"
import { getChildById } from "@/services/Child"
import { handlePendingUploads, queuePendingUpload } from "@/services/Upload"
import { getAccountInfo } from "@/utils/GlobalState"
import { isEmpty } from "@/utils/Utilities"

/**
 * Return a feeding screening based on internal assessment id, or null.
  [Wrapped if child is supplied.]
 * If no id is provided, then child must be supplied; an incomplete assessment is assumed.
 */
export async function getFeedingScreeningForChild(id?: number, child?: Child): Promise<IFeedingScreening> {
  let obj: IFeedingScreening
  if (id) {
    obj = await db.feedingScreenings.get(id)
  }
  else {
    obj = await db.feedingScreenings
      .where({ childId: child.id })
      .and(item => !item.isComplete)
      .last()
  }
  if (!obj) return
  return child ? new FeedingScreening(child, obj) : obj
}

/**
 * - Create or replace an instance of a feedingScreening in IndexedDB and return its id.
 */
export async function createOrReplaceFeedingScreening(child: IChild, screening: IFeedingScreening): Promise<number> {
  const saveableData = {} as IFeedingScreening
  const fields = [
    "isComplete",
    "id",
    "childId",
    "siteId",
    "dateOfAssessment",
    "notes",
    "doesChildCommunicateHunger",
    "doesChildEatEnough",
    "mealtimeDuration",
    "doesChildShowSignsOfAspiration",
    "doesChildEatReluctantly",
    "isChildNotGainingWeight",
    "areMealtimesStressful",
    "doesCaregiverHaveMealtimeConcerns",
    "hasReferrals",

  ]
  fields.forEach((fieldName) => {
    if (fieldName in screening) {
      const value = screening[fieldName]
      saveableData[fieldName] = isProxy(value) ? toRaw(value) : value
    }
  })

  saveableData.siteId = child.siteId
  return await db.feedingScreenings.put(saveableData)
}

export async function finalizeFeedingScreening(child: IChild, assessment: IFeedingScreening) {
  if (isProxy(child)) {
    child = toRaw(child)
  }
  const { name } = getAccountInfo()
  const childAsModel = new Child(child)
  const screeningAsModel = new FeedingScreening(childAsModel, assessment)
  assessment.hasReferrals = Boolean(screeningAsModel.referrals?.length)
  assessment.creatorName = name

  // Update child's next due date/next step?
  let newDueDate: Date
  let newType: string | undefined
  if (screeningAsModel.isAtRiskOfFeedingDifficulties) {
    newDueDate = new Date()
    newType = "observation"
  }
  else {
    newDueDate = childAsModel.getNextFeedingScreeningDate(screeningAsModel.dateOfAssessment, false)
    newType = newDueDate ? "screening" : "aged-out"
  }

  await db.transaction("rw", db.children, db.feedingScreenings, db.pendingUploads, async () => {
    const promises = [
      db.feedingScreenings.update(assessment.id, { hasReferrals: assessment.hasReferrals, creatorName: assessment.creatorName }),
      queuePendingUpload({ type: "feedingScreening", localItemId: assessment.id })
    ]
    if (!assessment.dueDate) {
      assessment.dueDate = child.nextMealtimeAssessmentDate || new Date()
      promises.push(
        db.feedingScreenings.update(assessment.id, { dueDate: assessment.dueDate })
      )
    }

    const childChanges = {}
    if (child.nextMealtimeAssessmentDate !== newDueDate) {
      childChanges["nextMealtimeAssessmentDate"] = newDueDate
      child.nextMealtimeAssessmentDate = newDueDate
    }
    if (child.nextMealtimeAssessmentType !== newType) {
      childChanges["nextMealtimeAssessmentType"] = newType
      child.nextMealtimeAssessmentType = newType
    }
    if (!isEmpty(childChanges)) {
      promises.push(
        db.children.update(child.id, childChanges),
        queuePendingUpload({ type: "child", localItemId: child.id })
      )
    }
    return Promise.all(promises)
  })
  return assessment.id
}

/**
 * Return an array of the screenings for this child (based on their internal id).
 * Assessments are sorted in chrono order.
 * @args {Number}   childId – this is the internal IndexedDB id, not from server
 * @return [Object]
 */
export async function getAllSavedFeedingScreeningsForChild(childId: number): Promise<Array<IFeedingScreening>> {
  return await db.feedingScreenings
    .where({ childId })
    .sortBy("dateOfAssessment")
}

// Return *wrapped* instance of FeedingScreening.
// Expects *wrapped* instance of child as arg.
export async function getLastSavedFeedingScreeningForChild(child: Child) {
  const assessments = await getAllSavedFeedingScreeningsForChild(child.id)
  const fs = assessments.filter(a => a.isComplete).pop()
  if (fs) {
    return new FeedingScreening(child, fs)
  }
}

/**
 * Return an array of the observations for this child (based on their internal id).
 * Assessments are sorted in chrono order.
 * @args {Number}   childId – this is the internal IndexedDB id, not from server
 * @return [Object]
 */
export async function getAllSavedFeedingObservationsForChild(childId: number): Promise<Array<IFeedingObservation>> {
  return await db.feedingObservations
    .where({ childId })
    .sortBy("dateOfAssessment")
}

// Return *wrapped* instance of FeedingScreening.
// Expects *wrapped* instance of child as arg.
export async function getLastSavedFeedingObservationForChild(child: Child) {
  const observations = await getAllSavedFeedingObservationsForChild(child.id)
  const fo = observations.filter(obs => obs.isComplete).pop()
  if (fo) {
    return new FeedingObservation(child, fo)
  }
}

/*
 * Given a feedingScreening id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} feedingScreeningId - internal id for pulling from queue
 * @return {Object} feedingScreening object from API
 */
export async function uploadFeedingScreening(feedingScreeningId: number) {
  const feedingScreening = await getFeedingScreeningForChild(feedingScreeningId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(feedingScreening.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a feeding screening for a child lacking a CMI id.")
  }
  feedingScreening.childId = child.cmiId
  delete feedingScreening.isComplete
  return api.uploadFeedingScreening(feedingScreening).then(async (data: IFeedingScreening) => {
    // If a successful POST, update the internal CMI id
    if (!feedingScreening.cmiId) {
      await db.feedingScreenings.update(feedingScreening.id, { cmiId: data.id })
    }
    return data
  })
}

/*
 * Given a feeding observation id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} feedingObservationId - internal id for pulling from queue
 * @return {Object} feedingObservation object from API
 */
export async function uploadFeedingObservation(feedingObservationId: number) {
  const feedingObservation = await getFeedingObservationForChild(feedingObservationId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(feedingObservation.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a feeding observation for a child lacking a CMI id.")
  }
  feedingObservation.childId = child.cmiId
  delete feedingObservation.isComplete
  return api.uploadFeedingObservation(feedingObservation).then(async (data: IFeedingObservation) => {
    // If a successful POST, update the internal CMI id
    if (!feedingObservation.cmiId) {
      await db.feedingObservations.update(feedingObservation.id, { cmiId: data.id })
    }
    return data
  })
}

/*
 * Given a positioning observation id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} positioningObservationId - internal id for pulling from queue
 * @return {Object} positioningObservation object from API
 */
export async function uploadPositioningObservation(positioningObservationId: number) {
  const positioningObservation = await db.positioningObservations.get(positioningObservationId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(positioningObservation.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a positioning observation for a child lacking a CMI id.")
  }
  positioningObservation.childId = child.cmiId
  delete positioningObservation.isComplete

  // Add the feeding observation CMI id
  const feedingObservation = await db.feedingObservations.get(positioningObservation.feedingObservationId)
  if (feedingObservation && feedingObservation.cmiId) {
    positioningObservation.feedingObservationId = feedingObservation.cmiId
  } else {
    throw new Error("Can't upload a positioning observation for a feeding observation lacking a CMI id.")
  }

  return api.uploadPositioningObservation(positioningObservation).then(async (data: IPositioningObservation) => {
    // If a successful POST, update the internal CMI id
    if (!positioningObservation.cmiId) {
      await db.positioningObservations.update(positioningObservation.id, { cmiId: data.id })
    }
    return data
  })
}

/*
 * Given a cup/spoon observation id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} cupOrSpoonObservationId - internal id for pulling from queue
 * @return {Object} cupOrSpoonObservation object from API
 */
export async function uploadCupOrSpoonObservation(cupOrSpoonObservationId: number) {
  const cupOrSpoonObservation = await db.cupOrSpoonObservations.get(cupOrSpoonObservationId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(cupOrSpoonObservation.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a cup/spoon observation for a child lacking a CMI id.")
  }
  cupOrSpoonObservation.childId = child.cmiId
  delete cupOrSpoonObservation.isComplete

  // Add the feeding observation CMI id
  const feedingObservation = await db.feedingObservations.get(cupOrSpoonObservation.feedingObservationId)
  if (feedingObservation && feedingObservation.cmiId) {
    cupOrSpoonObservation.feedingObservationId = feedingObservation.cmiId
  } else {
    throw new Error("Can't upload a cup/spoon observation for a feeding observation lacking a CMI id.")
  }

  return api.uploadCupOrSpoonObservation(cupOrSpoonObservation).then(async (data: ICupOrSpoonObservation) => {
    // If a successful POST, update the internal CMI id
    if (!cupOrSpoonObservation.cmiId) {
      await db.cupOrSpoonObservations.update(cupOrSpoonObservation.id, { cmiId: data.id })
    }
    return data
  })
}

/*
 * Given a self-feeding observation id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} selfFeedingObservationId - internal id for pulling from queue
 * @return {Object} selfFeedingObservation object from API
 */
export async function uploadSelfFeedingObservation(selfFeedingObservationId: number) {
  const selfFeedingObservation = await db.selfFeedingObservations.get(selfFeedingObservationId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(selfFeedingObservation.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a self-feeding observation for a child lacking a CMI id.")
  }
  selfFeedingObservation.childId = child.cmiId
  delete selfFeedingObservation.isComplete

  // Add the feeding observation CMI id
  const feedingObservation = await db.feedingObservations.get(selfFeedingObservation.feedingObservationId)
  if (feedingObservation && feedingObservation.cmiId) {
    selfFeedingObservation.feedingObservationId = feedingObservation.cmiId
  } else {
    throw new Error("Can't upload a self-feeding observation for a feeding observation lacking a CMI id.")
  }

  return api.uploadSelfFeedingObservation(selfFeedingObservation).then(async (data: ISelfFeedingObservation) => {
    // If a successful POST, update the internal CMI id
    if (!selfFeedingObservation.cmiId) {
      await db.selfFeedingObservations.update(selfFeedingObservation.id, { cmiId: data.id })
    }
    return data
  })
}


/*
 * Given a local feedingScreening id, look up the object in the pendingUploads queue
 * and retrieve the screening from the database. If the screening lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * screening. Otherwise, upload the entire notes object from the screening.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} feedingScreeningId - internal id for pulling from queue
 * @return {Object} feedingScreening object from API
 */
export async function uploadFeedingScreeningNote(feedingScreeningId: number) {
  const feedingScreening = await getFeedingScreeningForChild(feedingScreeningId)
  if (feedingScreening.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(feedingScreening.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a feeding screening note for a child lacking a CMI id.")
    }
    feedingScreening.childId = child.cmiId
    return await api.uploadFeedingScreening(feedingScreening, true)
  }
}

/*
 * Given a local feedingObservation id, look up the object in the pendingUploads queue
 * and retrieve the observation from the database. If the observation lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * observation. Otherwise, upload the entire notes object from the observation.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} feedingObservationId - internal id for pulling from queue
 * @return {Object} feedingObservation object from API
 */
export async function uploadFeedingObservationNote(feedingObservationId: number) {
  const feedingObservation = await getFeedingObservationForChild(feedingObservationId)
  if (feedingObservation.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(feedingObservation.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a feeding observation note for a child lacking a CMI id.")
    }
    feedingObservation.childId = child.cmiId
    return await api.uploadFeedingObservation(feedingObservation, true)
  }
}

/*
 * Given a local positioningObservation id, look up the object in the pendingUploads queue
 * and retrieve the observation from the database. If the observation lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * observation. Otherwise, upload the entire notes object from the observation.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} positioningObservationId - internal id for pulling from queue
 * @return {Object} positioningObservation object from API
 */
export async function uploadPositioningObservationNote(positioningObservationId: number) {
  const positioningObservation = await db.positioningObservations.get(positioningObservationId)
  if (positioningObservation.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(positioningObservation.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a positioning observation note for a child lacking a CMI id.")
    }
    positioningObservation.childId = child.cmiId

    // Get the feeding observation's CMI id
    const feedingObservation = await db.feedingObservations.get(positioningObservation.feedingObservationId)
    if (feedingObservation && feedingObservation.cmiId) {
      positioningObservation.feedingObservationId = feedingObservation.cmiId
    } else {
      throw new Error("Can't upload a positioning observation note for a feeding observation lacking a CMI id.")
    }

    return await api.uploadPositioningObservation(positioningObservation, true)
  }
}

/*
 * Given a local cupOrSpoonObservation id, look up the object in the pendingUploads queue
 * and retrieve the observation from the database. If the observation lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * observation. Otherwise, upload the entire notes object from the observation.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} cupOrSpoonObservationId - internal id for pulling from queue
 * @return {Object} cupOrSpoonObservation object from API
 */
export async function uploadCupOrSpoonObservationNote(cupOrSpoonObservationId: number) {
  const cupOrSpoonObservation = await db.cupOrSpoonObservations.get(cupOrSpoonObservationId)
  if (cupOrSpoonObservation.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(cupOrSpoonObservation.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a cup/spoon observation note for a child lacking a CMI id.")
    }
    cupOrSpoonObservation.childId = child.cmiId

    // Get the feeding observation's CMI id
    const feedingObservation = await db.feedingObservations.get(cupOrSpoonObservation.feedingObservationId)
    if (feedingObservation && feedingObservation.cmiId) {
      cupOrSpoonObservation.feedingObservationId = feedingObservation.cmiId
    } else {
      throw new Error("Can't upload a cup/spoon observation note for a feeding observation lacking a CMI id.")
    }

    return await api.uploadCupOrSpoonObservation(cupOrSpoonObservation, true)
  }
}

/*
 * Given a local selfFeedingObservation id, look up the object in the pendingUploads queue
 * and retrieve the observation from the database. If the observation lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * observation. Otherwise, upload the entire notes object from the observation.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} selfFeedingObservationId - internal id for pulling from queue
 * @return {Object} selfFeedingObservation object from API
 */
export async function uploadSelfFeedingObservationNote(selfFeedingObservationId: number) {
  const selfFeedingObservation = await db.selfFeedingObservations.get(selfFeedingObservationId)
  if (selfFeedingObservation.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(selfFeedingObservation.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a self-feeding observation note for a child lacking a CMI id.")
    }
    selfFeedingObservation.childId = child.cmiId

    // Get the feeding observation's CMI id
    const feedingObservation = await db.feedingObservations.get(selfFeedingObservation.feedingObservationId)
    if (feedingObservation && feedingObservation.cmiId) {
      selfFeedingObservation.feedingObservationId = feedingObservation.cmiId
    } else {
      throw new Error("Can't upload a self-feeding observation note for a feeding observation lacking a CMI id.")
    }

    return await api.uploadSelfFeedingObservation(selfFeedingObservation, true)
  }
}


/**
 * Replace all feeding screenings for a child with what's on the server. After handling
 * pending updates, of course! Doesn't return anything.
 * @args [Number] childId - this is the *internal* id!
 */
export async function updateFeedingScreeningsFromServer(childId: number, skipUpload = false): Promise<void> {
  if (!skipUpload) {
    try {
      handlePendingUploads()
    }
    catch (error) {
      console.log(error)
      return
    }
  }
  // Determine child's CMI id
  const child = await getChildById(childId)
  if (!child.cmiId) {
    throw new Error("Can't poll for a child lacking cmi id!")
  }
  // Fetch from server
  const screenings = await api.getFeedingScreeningsForChild(child.cmiId)

  // Remap the child ids to the internal one.
  screenings.forEach((fs: IFeedingScreening) => {
    fs.childId = childId
    fs.isComplete = true
    fs.siteId = child.siteId
  })

  // Purge previous records
  await db.feedingScreenings.where({ childId }).delete()

  // Update with the new
  await db.feedingScreenings.bulkAdd(screenings)
}

export function getFoldInSiteFeedingScreeningsFromServer(siteId: number) {
  async function foldInSiteFeedingScreeningsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const screeningCmiIdToInternalIds = {}
    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    await db.feedingScreenings
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<IFeedingScreening>) => results.forEach((a: IFeedingScreening) => screeningCmiIdToInternalIds[a.cmiId] = a.id))

    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    assessments.forEach((assessment: IFeedingScreening) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]
      assessment.isComplete = true
      assessment.siteId = siteId
      if (assessment.cmiId in screeningCmiIdToInternalIds) {
        db.feedingScreenings.update(screeningCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.feedingScreenings.add(assessment)
      }
    })
  }
  return foldInSiteFeedingScreeningsFromServer
}

/**
 * Replace all feeding observations–including sub-observations!–for a child
 * with what's on the server. Doesn't return anything.
 * @args [Number] childId - this is the *internal* id!
 */
export async function updateFeedingObservationsFromServer(childId: number, skipUpload = false): Promise<void> {
  if (!skipUpload) {
    try {
      handlePendingUploads()
    }
    catch (error) {
      console.log(error)
      return
    }
  }
  // Determine child's CMI id
  const child = await getChildById(childId)
  if (!child.cmiId) {
    throw new Error("Can't poll for a child lacking cmi id!")
  }
  // Fetch from server
  const feedingObservations = await api.getFeedingObservationsForChild(child.cmiId)

  // Remap the child ids to the internal one.
  feedingObservations.forEach((fo: IFeedingObservation) => {
    fo.childId = childId
    fo.isComplete = true
    fo.siteId = child.siteId
  })

  // Need to insert the new observations first so we can get their ids.
  // Unfortunately, that makes two separate transactions, and thus a chance
  // at internal inconsistency.
  await db.transaction("rw", db.feedingObservations, async () => {
    // Purge previous records
    await db.feedingObservations.where({ childId }).delete()

    // Update with the new
    await db.feedingObservations.bulkAdd(feedingObservations)
  })
  // Map feeding observation CMI ids to their internal ids
  const foCmiIds = Object.fromEntries((await db.feedingObservations.where({ childId }).toArray()).map((fo: IFeedingObservation) => [fo.cmiId, fo.id] ))

  //****
  //** Sub-observations types.
  //****

  let positioningObservations: Array<object>,
    cupOrSpoonObservations: Array<object>,
    selfFeedingObservations: Array<object>

  await Promise.all([
    (async () => positioningObservations = await api.getPositioningObservationsForChild(child.cmiId))(),
    (async () => cupOrSpoonObservations = await api.getCupOrSpoonObservationsForChild(child.cmiId))(),
    (async () => selfFeedingObservations = await api.getSelfFeedingObservationsForChild(child.cmiId))(),
  ])

  // mapFeedingObservationIds(positioningObservations)
  // mapFeedingObservationIds(cupOrSpoonObservations)
  // mapFeedingObservationIds(selfFeedingObservations)

  // Remap the child ids to the internal one.
  function mapper(obs: { childId: number, isComplete: boolean, siteId: number, feedingObservationId: number }) {
    obs.childId = childId
    obs.isComplete = true
    obs.siteId = child.siteId
    if (obs.feedingObservation && foCmiIds[obs.feedingObservation]) {
      obs.feedingObservationId = foCmiIds[obs.feedingObservation]
      delete obs.feedingObservation
    }
  }

  positioningObservations.forEach(mapper)
  cupOrSpoonObservations.forEach(mapper)
  selfFeedingObservations.forEach(mapper)

  await db.transaction("rw", db.positioningObservations, db.cupOrSpoonObservations, db.selfFeedingObservations, async () => {
    const promises = [
      db.positioningObservations.where({ childId }).delete().then(() => db.positioningObservations.bulkAdd(positioningObservations)),
      db.cupOrSpoonObservations.where({ childId }).delete().then(() => db.cupOrSpoonObservations.bulkAdd(cupOrSpoonObservations)),
      db.selfFeedingObservations.where({ childId }).delete().then(() => db.selfFeedingObservations.bulkAdd(selfFeedingObservations)),
    ]
    await Promise.all(promises)
  })
}

// Feeding Observation syncing functions
export function getFoldInSiteFeedingObservationsFromServer(siteId: number) {
  async function foldInSiteFeedingObservationsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const observationCmiIdToInternalIds = {}
    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    await db.feedingObservations
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<IFeedingObservation>) => results.forEach((a: IFeedingObservation) => observationCmiIdToInternalIds[a.cmiId] = a.id))

    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    assessments.forEach((assessment: IFeedingObservation) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]
      assessment.isComplete = true
      assessment.siteId = siteId
      if (assessment.cmiId in observationCmiIdToInternalIds) {
        db.feedingObservations.update(observationCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.feedingObservations.add(assessment)
      }
    })
  }
  return foldInSiteFeedingObservationsFromServer
}

export function getFoldInSitePositioningObservationsFromServer(siteId: number) {
  async function foldInSitePositioningObservationsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    // - all currently cached feeding observations for this site (parent observations)
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const observationCmiIdToInternalIds = {}
    const feedingObservationCmiIdToInternalIds = {}

    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    // Get all feeding observations to map server IDs to local IDs
    await db.feedingObservations
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<IFeedingObservation>) => {
        results.forEach((obs: IFeedingObservation) => {
          if (obs.cmiId) {
            feedingObservationCmiIdToInternalIds[obs.cmiId] = obs.id
          }
        })
      })

    await db.positioningObservations
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<IPositioningObservation>) => results.forEach((a: IPositioningObservation) => observationCmiIdToInternalIds[a.cmiId] = a.id))

    // Handle update/insert of the API results
    assessments.forEach((assessment: IPositioningObservation) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]

      // Convert server feeding observation ID to local ID
      if (assessment["feedingObservation"] && feedingObservationCmiIdToInternalIds[assessment["feedingObservation"]]) {
        assessment["feedingObservationId"] = feedingObservationCmiIdToInternalIds[assessment["feedingObservation"]]
      }

      assessment.isComplete = true
      assessment.siteId = siteId
      if (assessment.cmiId in observationCmiIdToInternalIds) {
        db.positioningObservations.update(observationCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.positioningObservations.add(assessment)
      }
    })
  }
  return foldInSitePositioningObservationsFromServer
}

export function getFoldInSiteCupOrSpoonObservationsFromServer(siteId: number) {
  async function foldInSiteCupOrSpoonObservationsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    // - all currently cached feeding observations for this site (parent observations)
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const observationCmiIdToInternalIds = {}
    const feedingObservationCmiIdToInternalIds = {}

    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    // Get all feeding observations to map server IDs to local IDs
    await db.feedingObservations
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<IFeedingObservation>) => {
        results.forEach((obs: IFeedingObservation) => {
          if (obs.cmiId) {
            feedingObservationCmiIdToInternalIds[obs.cmiId] = obs.id
          }
        })
      })

    await db.cupOrSpoonObservations
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<ICupOrSpoonObservation>) => results.forEach((a: ICupOrSpoonObservation) => observationCmiIdToInternalIds[a.cmiId] = a.id))

    // Handle update/insert of the API results
    assessments.forEach((assessment: ICupOrSpoonObservation) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]

      // Convert server feeding observation ID to local ID
      if (assessment["feedingObservation"] && feedingObservationCmiIdToInternalIds[assessment["feedingObservation"]]) {
        assessment["feedingObservationId"] = feedingObservationCmiIdToInternalIds[assessment["feedingObservation"]]
      }

      assessment.isComplete = true
      assessment.siteId = siteId
      if (assessment.cmiId in observationCmiIdToInternalIds) {
        db.cupOrSpoonObservations.update(observationCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.cupOrSpoonObservations.add(assessment)
      }
    })
  }
  return foldInSiteCupOrSpoonObservationsFromServer
}

export function getFoldInSiteSelfFeedingObservationsFromServer(siteId: number) {
  async function foldInSiteSelfFeedingObservationsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    // - all currently cached feeding observations for this site (parent observations)
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const observationCmiIdToInternalIds = {}
    const feedingObservationCmiIdToInternalIds = {}

    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    // Get all feeding observations to map server IDs to local IDs
    await db.feedingObservations
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<IFeedingObservation>) => {
        results.forEach((obs: IFeedingObservation) => {
          if (obs.cmiId) {
            feedingObservationCmiIdToInternalIds[obs.cmiId] = obs.id
          }
        })
      })

    await db.selfFeedingObservations
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<ISelfFeedingObservation>) => results.forEach((a: ISelfFeedingObservation) => observationCmiIdToInternalIds[a.cmiId] = a.id))

    // Handle update/insert of the API results
    assessments.forEach((assessment: ISelfFeedingObservation) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]

      // Convert server feeding observation ID to local ID
      if (assessment["feedingObservation"] && feedingObservationCmiIdToInternalIds[assessment["feedingObservation"]]) {
        assessment["feedingObservationId"] = feedingObservationCmiIdToInternalIds[assessment["feedingObservation"]]
      }

      assessment.isComplete = true
      assessment.siteId = siteId
      if (assessment.cmiId in observationCmiIdToInternalIds) {
        db.selfFeedingObservations.update(observationCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.selfFeedingObservations.add(assessment)
      }
    })
  }
  return foldInSiteSelfFeedingObservationsFromServer
}

// =======================
// == Feeding Observations
// =======================
/**
 * Return a feeding observation object based on internal assessment id, or null.
  [Wrapped if child is supplied.]
 * If no id is provided, then child must be supplied; an incomplete assessment is assumed.
 */
export async function getFeedingObservationForChild(id?: number, child?: Child): Promise<FeedingObservation | IFeedingObservation> {
  let obj: IFeedingObservation
  if (id) {
    obj = await db.feedingObservations.get(id)
  }
  else {
    obj = await db.feedingObservations
      .where({ childId: child.id })
      .and(item => !item.isComplete)
      .last()
  }
  if (!obj) return
  return child ? new FeedingObservation(child, obj) : obj
}

/**
 * - Create or replace an instance of a feedingObservation in IndexedDB and return its id.
 */
export async function createOrReplaceFeedingObservation(child: IChild, observation: IFeedingObservation): Promise<number> {
  const saveableData = {} as IFeedingObservation
  const fields = [
    "isComplete",
    "id",
    "cmiId",
    "childId",
    "siteId",
    "dateOfAssessment",
    "feedingProgress",
    "dueDate",
    "notes",
    "tools",
    "textures",
    "pureeOffered",
    "forkMashedOffered",
    "isChildChewing",
    "feedingAssistanceLevel",
    "hasChildTriedIndependence",
    "creatorName",
    "positioningObservationId",
    "cupOrSpoonObservationId",
    "selfFeedingObservationId",
    "infantObservationId",
    "hasReferrals",
  ]
  fields.forEach((fieldName) => {
    if (fieldName in observation) {
      const value = observation[fieldName]
      saveableData[fieldName] = isProxy(value) ? toRaw(value) : value
    }
  })

  saveableData.siteId = child.siteId
  return await db.feedingObservations.put(saveableData)
}

export async function finalizeFeedingObservation(child: IChild, observation: FeedingObservation) {
  const { name } = getAccountInfo()
  observation.creatorName = name

  // Update child's next due date?
  let newDueDate: Date | undefined
  let newType: string | undefined
  // Get child assessments
  await observation.processAssessment()

  // If there were concerns or if it was incomplete, have them come back in a few weeks.
  if (observation.reportCard.stoplightColor === "red" || !observation.isComplete) {
    newDueDate = dayjs(observation.dateOfAssessment).add(2, "weeks").toDate()
    newType = "observation"
  }
  else {
    newDueDate = observation.child.getNextFeedingScreeningDate(observation.dateOfAssessment, false)
    // If they're old enough, age out if observation was 100% clean.
    newType = newDueDate ? "screening" : "aged-out"
  }

  async function transactionFunction() {
    const obsChanges = { creatorName: observation.creatorName, isComplete: true }
    if (!observation.dueDate) {
      observation.dueDate = child.nextMealtimeAssessmentDate || new Date()
      obsChanges["dueDate"] = observation.dueDate
    }
    const promises = [
      db.feedingObservations.update(observation.id, obsChanges),
      queuePendingUpload({ type: "feedingObservation", localItemId: observation.id })
    ]

    if (observation.positioningObservation.isComplete) {
      promises.push(queuePendingUpload({ type: "positioningObservation", localItemId: observation.positioningObservation.id }))
    }
    if (observation.cupOrSpoonObservation.isComplete) {
      promises.push(queuePendingUpload({ type: "cupOrSpoonObservation", localItemId: observation.cupOrSpoonObservation.id }))
    }
    if (observation.selfFeedingObservation.isComplete) {
      promises.push(queuePendingUpload({ type: "selfFeedingObservation", localItemId: observation.selfFeedingObservation.id }))
    }

    const childChanges = {}
    if (child.nextMealtimeAssessmentDate !== newDueDate) {
      childChanges["nextMealtimeAssessmentDate"] = newDueDate
      child.nextMealtimeAssessmentDate = newDueDate
    }
    if (child.nextMealtimeAssessmentType !== newType) {
      childChanges["nextMealtimeAssessmentType"] = newType
      child.nextMealtimeAssessmentType = newType
    }
    if (!isEmpty(childChanges)) {
      promises.push(
        db.children.update(child.id, childChanges),
        queuePendingUpload({ type: "child", localItemId: child.id })
      )
    }
    return Promise.all(promises)
  }

  const transactionTables = [
    db.children,
    db.feedingObservations,
    db.positioningObservations,
    db.pendingUploads,
    db.positioningObservations,
    db.cupOrSpoonObservations,
    db.selfFeedingObservations,
  ]

  await db.transaction("rw", transactionTables, transactionFunction)
  return observation.id
}

interface ObservationLike {
  feedingObservation?: number // From API; maps to CMI id.
  feedingObservationId?: number // The local version; maps to local id.
}

export async function mapFeedingObservationIds(observations: Array<ObservationLike>): Promise<void> {
  const feedingObservations = await db.feedingObservations.filter(obs => obs.cmiId !== null).toArray()
  const idMap = Object.fromEntries(feedingObservations.map(obs => [obs.cmiId, obs.id]))
  observations.forEach(obs => {
    obs.feedingObservationId = idMap[obs.feedingObservation]
    delete obs.feedingObservation
  })
}

// Return a wrapped PositioningObservation–the one and only one associated with the
// supplied FeedingObservation. If one doesn't yet exist, create it first (and update parent).
export async function getOrCreatePositioningObservation(parent: FeedingObservation, createIfNonexistent: boolean) {
  let obj: IPositioningObservation
  obj = await db.positioningObservations.get({ feedingObservationId: parent.id })
  if (obj) {
    return new PositioningObservation(parent.child, obj)
  }
  else if (!createIfNonexistent) {
    return null
  }
  else {
    obj = {
      childId: parent.childId,
      feedingObservationId: parent.id,
      siteId: parent.siteId,
      isComplete: false,
      dateOfAssessment: null,
      positioningStyle: null,
      positionHead: null,
      positionHips: null,
      positionTrunk: null,
    }
    obj.id = await db.positioningObservations.add(obj)
    return new PositioningObservation(parent.child, obj)
  }
}

export async function replacePositioningObservation(observation: IPositioningObservation): Promise<number> {
  const saveableData = {} as IPositioningObservation
  const fields = [
    "isComplete",
    "id",
    "childId",
    "cmiId",
    "feedingObservationId",
    "dateOfAssessment",
    "notes",
    "creatorName",
    "positioningStyle",
    "positionHips",
    "positionTrunk",
    "positionHead",
    "olderChildHasSatUpright",
  ]
  fields.forEach((fieldName) => {
    if (fieldName in observation) {
      const value = observation[fieldName]
      saveableData[fieldName] = isProxy(value) ? toRaw(value) : value
    }
  })

  return await db.positioningObservations.put(saveableData)
}

// Return a wrapped CupOrSpoonObservation–the one and only one associated with the
// supplied FeedingObservation. If one doesn't yet exist, create it first (and update parent).
export async function getOrCreateCupOrSpoonObservation(parent: FeedingObservation, createIfNonexistent: boolean) {
  let obj: ICupOrSpoonObservation
  obj = await db.cupOrSpoonObservations.get({ feedingObservationId: parent.id })
  if (obj) {
    return new CupOrSpoonObservation(parent.child, obj)
  }
  else if (!createIfNonexistent) {
    return null
  }
  else {
    obj = {
      feedingObservationId: parent.id,
      siteId: parent.siteId,
      childId: parent.childId,
      isComplete: false,
      dateOfAssessment: null,
      residueAfterSwallow: null,
      headPositionWhenSwallowing: null,
      doesChildSwallowBite: null,
      headPositionWhenSwallowingLiquids: null,
      doesChildSwallowSip: null,
    }
    obj.id = await db.cupOrSpoonObservations.add(obj)
    return new CupOrSpoonObservation(parent.child, obj)
  }
}

export async function replaceCupOrSpoonObservation(observation: ICupOrSpoonObservation): Promise<number> {
  const saveableData = {} as ICupOrSpoonObservation
  const fields = [
    "isComplete",
    "id",
    "childId",
    "cmiId",
    "feedingObservationId",
    "dateOfAssessment",
    "notes",
    "tools",
    "creatorName",
    "residueAfterSwallow",
    "headPositionWhenSwallowing",
    "doesChildSwallowBite",
    "headPositionWhenSwallowingLiquids",
    "doesChildSwallowSip",
  ]
  fields.forEach((fieldName) => {
    if (fieldName in observation) {
      const value = observation[fieldName]
      saveableData[fieldName] = isProxy(value) ? toRaw(value) : value
    }
  })

  return await db.cupOrSpoonObservations.put(saveableData)
}

// Return a wrapped SelfFeedingObservation–the one and only one associated with the
// supplied FeedingObservation. If one doesn't yet exist, create it first (and update parent).
export async function getOrCreateSelfFeedingObservation(parent: FeedingObservation, createIfNonexistent: boolean) {
  let obj: ISelfFeedingObservation
  obj = await db.selfFeedingObservations.get({ feedingObservationId: parent.id })
  if (obj) {
    return new SelfFeedingObservation(parent.child, obj)
  }
  else if (!createIfNonexistent) {
    return null
  }
  else {
    obj = {
      feedingObservationId: parent.id,
      siteId: parent.siteId,
      childId: parent.childId,
      isComplete: false,
      dateOfAssessment: null,
    }
    obj.id = await db.selfFeedingObservations.add(obj)
    return new SelfFeedingObservation(parent.child, obj)
  }
}

export async function replaceSelfFeedingObservation(observation: ISelfFeedingObservation): Promise<number> {
  const saveableData = {} as ISelfFeedingObservation
  const fields = [
    "isComplete",
    "id",
    "childId",
    "cmiId",
    "feedingObservationId",
    "dateOfAssessment",
    "notes",
    "tools",
    "creatorName",
    "currentTools",
    "canMaintainUprightPosition",
    "canUseTool",
    "canUseCup",
    "canGetFoodToMouth",
  ]
  fields.forEach((fieldName) => {
    if (fieldName in observation) {
      const value = observation[fieldName]
      saveableData[fieldName] = isProxy(value) ? toRaw(value) : value
    }
  })

  return await db.selfFeedingObservations.put(saveableData)
}


// =================
// == Best Practices
// =================

/**
 * Return a feeding screening based on internal assessment id, or null.
  [Wrapped if child is supplied.]
 * If no id is provided, then child must be supplied; an incomplete assessment is assumed.
 */
export async function getChildBestPracticeAssessmentForChild(id?: number, child?: Child): Promise<IBestPracticeAssessment> {
  let obj: IBestPracticeAssessment
  if (id) {
    obj = await db.childBestPracticeAssessments.get(id)
  }
  else {
    obj = await db.childBestPracticeAssessments
      .where({ childId: child.id })
      .and(item => !item.isComplete)
      .last()
  }
  if (!obj) return
  return child ? new ChildBestPracticeAssessment(child, obj) : obj
}

/**
 * - Create or replace an instance of a feedingScreening in IndexedDB and return its id.
 */
export async function createOrReplaceChildBestPracticeAssessment(child: IChild, screening: IBestPracticeAssessment): Promise<number> {
  const saveableData = {} as IBestPracticeAssessment
  const fields = [
    "isComplete",
    "id",
    "cmiId",
    "childId",
    "siteId",
    "dateOfAssessment",
    "dueDate",
    "notes",
    "tools",
    "textures",
    "feeder",
    "bf",
    "rf1",
    "rf2",
    "rf3",
    "rf4",
    "rf5",
    "rf6",
    "rf7",
    "rf8",
    "fp1",
    "fp2",
    "fp3",
    "fp4",
    "fp5",
    "mf",
    "mff",
    "fg1a",
    "fg2a",
    "fg1b",
    "fg2b",
    "fg2c",
    "fg3a",
    "fg3b",
    "fg4",
    "fg5",
    "fg6",
    "fg7",
    "fg8",
    "hasReferrals",
  ]
  fields.forEach((fieldName) => {
    if (fieldName in screening) {
      const value = screening[fieldName]
      saveableData[fieldName] = isProxy(value) ? toRaw(value) : value
    }
  })

  saveableData.childId = child.id
  saveableData.siteId = child.siteId
  return await db.childBestPracticeAssessments.put(saveableData)
}

// Return *wrapped* instance of ChildBestPracticeAssessment. Expects *wrapped* instance
// of child as arg.
export async function getLastChildBestPracticeAssessmentForChild(child: Child) {
  const ds = await db.childBestPracticeAssessments
    .where({ childId: child.id })
    .filter(a => a.isComplete)
    .sortBy("dateOfAssessment")
  if (ds.length) {
    return new ChildBestPracticeAssessment(child, ds[ds.length - 1])
  }
}

export async function finalizeBestPracticesAssessment(child: IChild, assessment: IBestPracticeAssessment) {
  if (isProxy(child)) {
    child = toRaw(child)
  }
  const childAsModel = new Child(child)
  const { name } = getAccountInfo()
  assessment.creatorName = name

  // Update child's next due date?
  const newDueDate = childAsModel.getNextBestPracticesDate(assessment.dateOfAssessment)

  await db.transaction("rw", db.children, db.childBestPracticeAssessments, db.pendingUploads, async () => {
    const promises = [
      db.childBestPracticeAssessments.update(assessment.id, { hasReferrals: assessment.hasReferrals, creatorName: assessment.creatorName }),
      queuePendingUpload({ type: "childBestPracticeAssessment", localItemId: assessment.id })
    ]
    if (!assessment.dueDate) {
      assessment.dueDate = child.nextBestPracticesAssessmentDate || new Date()
      promises.push(
        db.childBestPracticeAssessments.update(assessment.id, { dueDate: assessment.dueDate })
      )
    }


    if (child.nextBestPracticesAssessmentDate !== newDueDate) {
      const changes = { nextBestPracticesAssessmentDate: newDueDate }
      child.nextBestPracticesAssessmentDate = newDueDate
      if (!newDueDate) {
        child.isAgedOutOfBestPracticesAssessment = true
        changes["isAgedOutOfBestPracticesAssessment"] = true
      }
      promises.push(
        db.children.update(child.id, changes),
        queuePendingUpload({ type: "child", localItemId: child.id })
      )
    }
    return Promise.all(promises)
  })
  return assessment.id
}


/*
 * Given an assessment id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} assessmentId - internal id for pulling from queue
 * @return {Object} BestPracticeAssessment object from API
 */
export async function uploadBestPractice(assessmentId: number) {
  const assessment = await getChildBestPracticeAssessmentForChild(assessmentId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(assessment.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a feeding screening for a child lacking a CMI id.")
  }
  assessment.childId = child.cmiId
  delete assessment.isComplete
  return api.uploadBestPracticeAssessment(assessment).then(async (data: IBestPracticeAssessment) => {
    // If a successful POST, update the internal CMI id
    if (!assessment.cmiId) {
      await db.childBestPracticeAssessments.update(assessment.id, { cmiId: data.id })
    }
    return data
  })
}


/*
 * Given a local assessment id, look up the object in the pendingUploads queue
 * and retrieve the screening from the database. If the screening lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * screening. Otherwise, upload the entire notes object from the screening.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} assessmentId - internal id for pulling from queue
 * @return {Object} BestPracticeAssessment object from API
 */
export async function uploadBestPracticeNote(assessmentId: number) {
  const assessment = await getChildBestPracticeAssessmentForChild(assessmentId)
  if (assessment.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(assessment.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a feeding screening note for a child lacking a CMI id.")
    }
    assessment.childId = child.cmiId
    return await api.uploadBestPracticeAssessment(assessment, true)
  }
}

/**
 * Replace all BPAs for a child with what's on the server. After handling
 * pending updates, of course! Doesn't return anything.
 * @args [Number] childId - this is the *internal* id!
 */
export async function updateBestPracticeAssessmentsFromServer(childId: number, skipUploads = false): Promise<void> {
  if (!skipUploads) {
    try {
      handlePendingUploads()
    }
    catch (error) {
      console.log(error)
      return
    }
  }
  // Determine child's CMI id
  const child = await getChildById(childId)
  if (!child.cmiId) {
    throw new Error("Can't poll for a child lacking cmi id!")
  }
  // Fetch from server
  const screenings = await api.getBestPracticeAssessmentsForChild(child.cmiId)

  // Remap the child ids to the internal one.
  screenings.forEach((fs: IBestPracticeAssessment) => {
    fs.childId = childId
    fs.isComplete = true
    fs.siteId = child.siteId
  })

  // Purge previous records
  await db.childBestPracticeAssessments.where({ childId }).delete()

  // Update with the new
  await db.childBestPracticeAssessments.bulkAdd(screenings)
}

export function getFoldInSiteBestPracticeAssessmentsFromServer(siteId: number) {
  async function foldInSiteBestPracticeAssessmentsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const assessmentCmiIdToInternalIds = {}
    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    await db.childBestPracticeAssessments
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<IBestPracticeAssessment>) => results.forEach((a: IBestPracticeAssessment) => assessmentCmiIdToInternalIds[a.cmiId] = a.id))

    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    assessments.forEach((assessment: IBestPracticeAssessment) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]
      assessment.isComplete = true
      assessment.siteId = siteId
      if (assessment.cmiId in assessmentCmiIdToInternalIds) {
        db.childBestPracticeAssessments.update(assessmentCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.childBestPracticeAssessments.add(assessment)
      }
    })
  }
  return foldInSiteBestPracticeAssessmentsFromServer
}
