import dayjs from "dayjs"
import { isProxy, toRaw } from "vue"

import { db, IAnemiaAssessment, IChild } from "@/db"
import { AnemiaAssessment } from "@/models/AnemiaAssessment"
import { Child } from "@/models/Child"
import * as api from "@/services/Api"
import { getChildById } from "@/services/Child"
import { handlePendingUploads, queuePendingUpload } from "@/services/Upload"
import { getAccountInfo } from "@/utils/GlobalState"

/**
 * Return the assessment based on internal id, or null.
 * Wrapped if child is supplied.
 * If no id is provided, then child must be supplied; an incomplete assessment is assumed.
 */
export async function getAnemiaAssessmentForChild(id?: number, child?: Child) {
  let obj: IAnemiaAssessment
  if (id) {
    obj = await db.anemiaAssessments.get(id)
  }
  else {
    obj = await db.anemiaAssessments
      .where({ childId: child.id })
      .and(item => !item.isComplete)
      .last()
  }
  if (!obj) return
  return child ? new AnemiaAssessment(child, obj) : obj
}

export function getFoldInSiteAnemiaAssessmentsFromServer(siteId: number) {
  async function foldInSiteAnemiaAssessmentsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const anemiaCmiIdToInternalIds = {}
    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    await db.anemiaAssessments
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<object>) => results.forEach((a: { cmiId: number, id: number }) => (anemiaCmiIdToInternalIds[a.cmiId] = a.id)))

    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    assessments.forEach((assessment: IAnemiaAssessment) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]
      assessment.siteId = siteId
      assessment.isComplete = true
      if (assessment.cmiId in anemiaCmiIdToInternalIds) {
        db.anemiaAssessments.update(anemiaCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.anemiaAssessments.add(assessment)
      }
    })
  }
  return foldInSiteAnemiaAssessmentsFromServer
}

/**
 * Return an array of the assessments for this child (based on their internal id).
 * Assessments are sorted in chrono order.
 * @args {Number}   childId – this is the internal IndexedDB id, not from server
 * @return [Object]
 */
export async function getAllSavedAnemiaAssessmentsForChild(childId: number) {
  return await db.anemiaAssessments
    .where({ childId })
    .sortBy("dateOfAssessment")
}

// Return *wrapped* instance of AnemiaAssessment.
// Expects *wrapped* instance of child as arg.
export async function getLastSavedAnemiaAssessmentForChild(child: Child) {
  const assessments = await getAllSavedAnemiaAssessmentsForChild(child.id)
  const aa = assessments.filter(a => a.isComplete).pop()
  if (aa) {
    return new AnemiaAssessment(child, aa)
  }
}

export async function getMostRecentAnemiaAssessmentForEachChild(siteId: number) {
  const allAssessments = await db.anemiaAssessments
    .where({ siteId })
    .reverse()
    .sortBy("dateOfAssessment")
  const childrenAlreadySeen = new Set()
  const results = []
  // Only extract the first assessment we find for each child.
  allAssessments.forEach((a: IAnemiaAssessment) => {
    if (!childrenAlreadySeen.has(a.childId)) {
      results.push(a)
      childrenAlreadySeen.add(a.childId)
    }
  })
  return results
}


/**
 * Replace all anemia assessments for a child with what's on the server. After handling
 * pending updates, of course! Doesn't return anything.
 * @args [Number] childId - this is the *internal* id!
 */
export async function updateAnemiaAssessmentsFromServer(childId: number, skipUploads = false) {
  if (!skipUploads) {
    try {
      await handlePendingUploads()
    }
    catch (error) {
      console.log(error)
      return
    }
  }
  // Determine child's CMI id
  const child = await getChildById(childId)
  if (!child.cmiId) {
    throw new Error("Can't poll for a child lacking cmi id!")
  }
  // Fetch from server
  const assessments = await api.getAnemiaAssessmentsForChild(child.cmiId)

  // Remap the child ids to the internal one.
  assessments.forEach(aa => {
    aa.childId = childId
    aa.siteId = child.siteId
    aa.isComplete = true
  })

  // Purge previous records
  await db.anemiaAssessments.where({ childId }).delete()

  // Update with the new
  await db.anemiaAssessments.bulkAdd(assessments)
}

/**
 * Create or replace an instance of an anemiaAssessment in IndexedDB and return its id.
 */
export async function createOrReplaceAnemiaAssessment(child: IChild, anemiaAssessment: IAnemiaAssessment) {
  // Prep object for persistence
  if (isProxy(anemiaAssessment)) {
    anemiaAssessment = toRaw(anemiaAssessment)
  }
  const saveableAnemiaAssessment = {} as IAnemiaAssessment
  // Ensure we're only persisting legit fields (others get larded in somehow)
  const fields = [
    "id",
    "cmiId",
    "isComplete",
    "childId",
    "siteId",
    "dueDate",
    "dateOfAssessment",
    "dateCreated",
    "testDate",
    // "creatorName",
    "hasInfection",
    "hasReferrals",
    "hemoglobinLevel",
    "testingLocation",
    "currentlyTakingIronSupplements",
    "ironSupplementsTakenWithVitaminC",
    "ironSupplementsMilligramsPerDay",
    "ironSupplementFrequency",
    "ironSupplementStartDate",
    "stage",
    "notes",
  ]
  fields.forEach((fieldName) => {
    if (fieldName in anemiaAssessment) {
      saveableAnemiaAssessment[fieldName] = anemiaAssessment[fieldName]
    }
  })
  saveableAnemiaAssessment.childId = child.id
  saveableAnemiaAssessment.siteId = child.siteId

  // Save locally
  anemiaAssessment.id = await db.anemiaAssessments.put(saveableAnemiaAssessment)
  return anemiaAssessment.id
}


// Possibly set the assessment's due date and possibly update the Child's next due date.
// Persist those changes if they did happen. Queue assessment for upload and possibly the child.
export async function finalizeAnemiaAssessment(child: IChild, assessment: IAnemiaAssessment) {
  // Update child's next assessment due date
  if (isProxy(child)) {
    child = toRaw(child)
  }
  const childAsModel = new Child(child)
  const anemiaAsModel = new AnemiaAssessment(childAsModel, assessment)
  await anemiaAsModel.processAssessment() // Generate follow up interval
  const { name } = getAccountInfo()
  assessment.hasReferrals = Boolean(anemiaAsModel.referrals?.length)
  assessment.creatorName = name

  const intervalInMonths = await anemiaAsModel.getAssessmentInterval()
  const newDueDate = dayjs(anemiaAsModel.dateOfAssessment).add(intervalInMonths, "month").toDate()

  await db.transaction("rw", db.children, db.anemiaAssessments, db.pendingUploads, async () => {
    const updates = {
      hasReferrals: assessment.hasReferrals,
      creatorName: assessment.creatorName,
      stage: anemiaAsModel.stage,
    }
    const promises = [
      db.anemiaAssessments.update(assessment.id, updates),
      queuePendingUpload({ type: "anemiaAssessment", localItemId: assessment.id })
    ]
    if (!assessment.dueDate) {
      assessment.dueDate = child.nextAnemiaAssessmentDate || new Date()
      promises.push(
        db.anemiaAssessments.update(assessment.id, { dueDate: assessment.dueDate })
      )
    }
    if (child.nextAnemiaAssessmentDate !== newDueDate) {
      child.nextAnemiaAssessmentDate = newDueDate
      promises.push(
        db.children.update(child.id, { nextAnemiaAssessmentDate: newDueDate }),
        queuePendingUpload({ type: "child", localItemId: child.id })
      )
    }
    return Promise.all(promises)
  })
}

/*
 * Given a anemiaAssessment id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 */
export async function uploadAnemiaAssessment(anemiaAssessmentId: number) {
  const anemiaAssessment = await getAnemiaAssessmentForChild(anemiaAssessmentId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(anemiaAssessment.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload an anemia assessment for a child lacking a CMI id.")
  }
  anemiaAssessment.childId = child.cmiId
  delete anemiaAssessment.isComplete
  return api.uploadAnemiaAssessment(anemiaAssessment).then(async (data: IAnemiaAssessment) => {
    // If a successful POST, update the internal CMI id
    if (!anemiaAssessment.cmiId) {
      await db.anemiaAssessments.update(anemiaAssessmentId, { cmiId: data.id, creatorName: data.creatorName })
    }
    return data
  })
}


/*
 * Given a local assessment id, look up the object in the pendingUploads queue
 * and retrieve the assessment from the database. If the assessment lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * assessment. Otherwise, upload the entire notes object from the assessment.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} assessmentId - internal id for pulling from queue
 * @return {Object} assessment object from API
 */
export async function uploadAnemiaAssessmentNote(assessmentId: number) {
  const assessment = await getAnemiaAssessmentForChild(assessmentId)
  if (assessment.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(assessment.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload an anemia assessment for a child lacking a CMI id.")
    }
    assessment.childId = child.cmiId
    return await api.uploadAnemiaAssessment(assessment, true)
  }
}
