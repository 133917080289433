import dayjs, { type Dayjs } from "dayjs"
import { isProxy, toRaw } from 'vue'

import { db, IChild, ISite } from "@/db"
import { Child, FEEDING_MILESTONES } from "@/models/Child"
import { updateAnemiaAssessmentsFromServer } from "@/services/AnemiaAssessment"
import * as api from "@/services/Api"
import { updateDevelopmentalScreeningsFromServer } from "@/services/DevelopmentalScreening"
import {
  getLastSavedFeedingScreeningForChild,
  getLastSavedFeedingObservationForChild,
  updateBestPracticeAssessmentsFromServer,
  updateFeedingScreeningsFromServer,
  updateFeedingObservationsFromServer,
} from "@/services/Feeding"
import { updateGrowthAssessmentsFromServer } from "@/services/GrowthAssessment"
import { getSiteByCmiId } from "@/services/Site"
import { handlePendingUploads, queuePendingUpload } from "@/services/Upload"
import {
  getLastUpdateInfo,
  updateLastUpdateTime
} from "@/utils/GlobalState"
import { gettext } from "@/utils/Translation"
import { isOnline } from "@/utils/Utilities"

const { $gettext } = gettext

/**
 * Returns a list of children that have assessments within the start and end time frame
 * @args {number} siteId - id for the site
 * @args {number} start - dayjs object for starting time frame
 * @args {number} end - dayjs object for ending time frame
 * @return [Array<Child>] list of child objects from API
 */
export async function getChildrenWithAssessmentsForMonth(siteId: number, month: Dayjs) {
  const children = await getCachedChildrenForSite(siteId)
  const startOfMonth = month.startOf("month")
  const endOfMonth = month.endOf("month")
  return children.filter((c) => {
    return (
      (c.nextAnemiaAssessmentDate &&
        startOfMonth.isBefore(c.nextAnemiaAssessmentDate) &&
        endOfMonth.isAfter(c.nextAnemiaAssessmentDate)) ||
      (c.nextEarlyidAssessmentDate &&
        startOfMonth.isBefore(c.nextEarlyidAssessmentDate) &&
        endOfMonth.isAfter(c.nextEarlyidAssessmentDate)) ||
      (c.nextGrowthAssessmentDate &&
        startOfMonth.isBefore(c.nextGrowthAssessmentDate) &&
        endOfMonth.isAfter(c.nextGrowthAssessmentDate)) ||
      (c.nextMealtimeAssessmentDate &&
        startOfMonth.isBefore(c.nextMealtimeAssessmentDate) &&
        endOfMonth.isAfter(c.nextMealtimeAssessmentDate)) ||
      (c.nextBestPracticesAssessmentDate &&
        startOfMonth.isBefore(c.nextBestPracticesAssessmentDate) &&
        endOfMonth.isAfter(c.nextBestPracticesAssessmentDate))
    )
  })
}

/**
 * Return a new Child instance based on data from IndexedDB, or null if not found.
 * @args {number} id - id for child
 * @return [Child]
 */
export async function getChildById(id: number) {
  const childData = await db.children.get(id)
  return childData ? new Child(childData) : null
}

/**
 * Save or update an instance of a child in IndexedDB;
 * queue its upload to the server;
 * return its id.
 * Note that it is up to the calling function to actually
 * attempt the upload.
 */
export async function finalizeChild(child: IChild, type = "child"): Promise<number> {
  if (isProxy(child)) {
    child = toRaw(child)
  }
  let childId: number
  await db.transaction("rw", db.children, db.pendingUploads, db.lastUpdated, async () => {
    if (child.id) {
      childId = child.id
      // update only touches the fields that are provided
      await db.children.update(childId, child)
    }
    else {
      // PUT either inserts (if new) or *replaces* (if existing) what's in the db
      childId = await db.children.put(child)
      // If this is a brand new child, then we assert that we have already
      // "updated" it from the server (that is, there's nothing new on the server).
      await updateLastUpdateTime({ type: "childAssessments", localItemId: childId })
    }
    queuePendingUpload({ type, localItemId: childId })
  })
  return childId
}

/*
 * Given a childId, look up the child in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * locally-persisted child's CMI id. If successful, return true.
 * Allow all exceptions to trickle up.
 * @args {Number} childId - internal child id for pulling from queue
 * @return {Object} child object from API
 */
export async function uploadChild(childId: number) {
  const child = await getChildById(childId)
  const includeNames = !child.cmiId || await getSiteByCmiId(child.siteId).then(site => site.canSeeNames)
  const toUpload = child.getDataToPersist({ includeNames })
  return api.uploadChild(toUpload).then(async function(data) {
    // If a successful POST, update the internal CMI id
    if (!child.cmiId) {
      await db.children.update(childId, { cmiId: data.id })
    }
    return data
  })
}

export async function markChildAsReal(siteId: number, cmiId: number) {
  const toUpload = { siteId, cmiId, createdDuringTraining: false }
  // Second arg means use patch
  await api.uploadChild(toUpload, true)
    .then(async () => {
      // Update the database with what we believed just happened.
      await db.children
        .where("cmiId")
        .equals(cmiId)
        .modify({ createdDuringTraining: false })
    })
}

/*
 * Given a childId, look up the child in the pendingUploads queue and attempt
 * to save discharge info. If successful, remove the entry from the queue.
 * Allow all exceptions to trickle up.
 * @args {Number} childId - internal child id for pulling from queue
 * @return {null}
 */
export async function dischargeChild(childId: number) {
  const child = await getChildById(childId)
  return api.dischargeChild(child, child.siteId)
}

export async function transferChild(childId: number) {
  const child = await getChildById(childId)
  // Swap in the date of discharge just for the purposes of the API call.
  child.dateOfDischarge = child.dateOfAdmission
  return api.transferChild(child, child.oldSiteId, child.siteId)
    .then(async () => {
      // Update local version of child with reference to old site removed
      delete child["oldSiteId"]
      delete child["dateOfDischarge"]
      await db.children.put(child) // Replace child in IDB.
    })
}

/**
 * Return a list of children sourced from IndexedDB for a particular site.
 * @args {Number} siteId - CMI id of the site in question
 * @args {Object} [options] - keys include sort ([default] id, first, last)
 * @args {Object} [options.sort] - Field to sort on ([default] id, first, last)
 * @args {Boolean} [options.dischargedOnly] - Only include children who have been
 *       discharged. (If false, only current children are included.)
 * @return [Array<Child>]
 */
export async function getCachedChildrenForSite(siteId: number, options?: { sort?: string, dischargedOnly?: boolean, due?: string }) {
  const today = new Date()
  options = options || {}
  const { sort = "id" } = options
  const { dischargedOnly = false } = options
  const children = []
  const fromDb = await db.children
    .where({ siteId })
    .sortBy(sort)
  for (let i = 0; i < fromDb.length; i++) {
    // Check discharge situation. This line translates to:
    // - if we want discharged children and there's no date of discharge, skip
    //   OR
    // - if we DON'T want discharged children and there IS a date of discharge, skip.
    if (dischargedOnly != Boolean(fromDb[i].dateOfDischarge)) {
      continue
    }
    children.push(new Child(fromDb[i]))
  }
  switch (options.due) {
    case "growth":
      return children.filter(
        (child) => !child.nextGrowthAssessmentDate || child.nextGrowthAssessmentDate <= today,
      )
    case "anemia":
      return children.filter(
        (child) => !child.nextAnemiaAssessmentDate || child.nextAnemiaAssessmentDate <= today,
      )
    case "feedingScreening":
      return children.filter((child: Child) => {
        if (!child.nextMealtimeAssessmentDate) return child.nextMealtimeAssessmentType !== "aged-out"
        return child.nextMealtimeAssessmentDate <= today && (!child.nextMealtimeAssessmentType || child.nextMealtimeAssessmentType === "screening")
      })
    case "feedingObservation":
      return children.filter((child: Child) => {
        return child.nextMealtimeAssessmentDate <= today && child.nextMealtimeAssessmentType && child.nextMealtimeAssessmentType.includes("observation")
      })
    case "bestPractice":
      return children.filter((child: Child) => {
        if (!child.nextBestPracticesAssessmentDate) return !child.isAgedOutOfBestPracticesAssessment
        return child.nextBestPracticesAssessmentDate <= today
      })
    case "earlyid":
      return children.filter((child: Child) => {
        if (child.nextEarlyidAssessmentDate) return child.nextEarlyidAssessmentDate <= today
        const ageInMonths = child.getAgeInMonths()
        // Age range for developmental screening is 1 month to 10 years.
        return ageInMonths > 1 && ageInMonths < 10 * 12
      })
  }
  return children
}

/**
 * Attempts to sync pending uploads. If successful, queries server for site list of children.
 * To maintain internal ids, the API results are folded back in based on CMI ids. If a child
 * is not returned in the API query but exists in the cache, delete it.
 * Underlying API function can throw the following exceptions; they're not caught here:
 *  - CONNECTIVITY_REQUIRED
 *  - LOGIN_REQUIRED
 *  - SERVER_ERROR
 */
export async function updateChildrenFromServer(siteId: number, discharged = false) {
  try {
    handlePendingUploads()
  } catch (error) {
    console.log(error)
    return
  }
  // Create a mapping of CMI ids to internal ids for all currently cached children for this site
  const cmiIdToInternalId = {}
  await db.children
    .where({ siteId })
    .toArray()
    .then((results: Array<object>) => results.forEach((child: IChild) => (cmiIdToInternalId[child.cmiId] = child.id)))

  // Fetch children from server
  const params = {}
  if (discharged) {
    params["discharged"] = true
  }
  const childrenFromServer = await api.getChildrenForSite(siteId, params)
  // Handle update/insert of the API results
  for (const child of childrenFromServer) {
    if (child.cmiId in cmiIdToInternalId) {
      await db.children.update(cmiIdToInternalId[child.cmiId], child)
    }
    else {
      await db.children.add(child)
    }
  }
  const site = await db.sites.get({ cmiId: siteId })
  const localSiteId = site.id
  updateLastUpdateTime({ type: discharged ? "siteChildrenDischarged" : "siteChildren", localItemId: localSiteId })
}

export function getFoldInSiteChildrenFromServer(siteId: number) {
  async function foldInSiteChildrenFromServer(children: Array<object>) {
    // Create a mapping of CMI ids to internal ids for all currently cached children for this site
    const cmiIdToInternalId = {}
    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => results.forEach((child: IChild) => (cmiIdToInternalId[child.cmiId] = child.id)))

    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    children.forEach((child: IChild) => {
      child.siteId = siteId
      if (child.cmiId in cmiIdToInternalId) {
        db.children.update(cmiIdToInternalId[child.cmiId], child)
      }
      else {
        db.children.add(child)
      }
    })
  }
  return foldInSiteChildrenFromServer
}

export async function updateChildsAssessmentsFromServer(childId: number, site: ISite) {
  const promises = []
  try {
    handlePendingUploads()
  }
  catch (error) {
    console.log(error)
    return
  }
  if (site.growthEnabled) {
    promises.push(updateGrowthAssessmentsFromServer(childId, true))
  }
  if (site.anemiaEnabled) {
    promises.push(updateAnemiaAssessmentsFromServer(childId, true))
  }
  if (site.earlyidEnabled) {
    promises.push(updateDevelopmentalScreeningsFromServer(childId, true))
  }
  if (site.isFeedingScreeningEnabled) {
    promises.push(updateFeedingScreeningsFromServer(childId, true))
  }
  if (site.isFeedingObservationEnabled) {
    promises.push(updateFeedingObservationsFromServer(childId, true))
  }
  if (site.isChildMealtimeBestPracticeAssessmentEnabled) {
    promises.push(updateBestPracticeAssessmentsFromServer(childId, true))
  }
  await Promise.all(promises)
  await updateLastUpdateTime({ type: "childAssessments", localItemId: childId })
}

// Determine if we should try polling the server for all assessments (of each flavor)
// for this child (and do so if needed) in preparation for a new assessment of some kind.
// (That is, before we do growth or anemia, we want to have historical data to base recs on.)
// Throw an exception if we can't do it but think we should.
export async function updateBeforeAssessing(child: Child): Promise<void> {
  if (!child.cmiId) {
    // If they don't have a CMI id, then they've never been synced to the server.
    // Nothing to pull down.
    return
  }
  const lastUpdateTime = await getLastUpdateInfo({ type: "childAssessments", localItemId: child.id })

  // Construct the error preemptively–we might not actually need it.
  const offlineError = new Error()
  offlineError.name = "CONNECTIVITY_REQUIRED"

  // We're generous with this, since they're not *likely* to be assessing a child AND there are new data
  // on the server.
  if (lastUpdateTime) {
    if (dayjs(lastUpdateTime).isAfter(dayjs().subtract(7, "day"))) {
      return
    }
    else {
      offlineError.message = $gettext("Previous assessments for this child have never been downloaded. Recommendations may not be as accurate. If possible, go online and download assessments for this child before assessing.")
    }
    offlineError.message = $gettext("Previous assessments for this child have not been updated recently. Recommendations may not be as accurate. If possible, go online and download assessments for this child before assessing.")
  }
  if (!isOnline) {
    throw offlineError
  }
  const site = await child.getSite()
  await updateChildsAssessmentsFromServer(child.id, site).catch(error => {
    throw error.name === "CONNECTIVITY_REQUIRED" ? offlineError : error
  })
}

export async function shouldChildDoFeedingScreeningInstead(child: Child): Promise<boolean> {
  // Assumes child is currently due for a feeding observation. Checks to see:
  // - was the last observation more than 1 month ago, AND
  // - was there no screening done since last observation, AND
  // - did the child pass a feeding milestone in the interim?
  const lastObs = await getLastSavedFeedingObservationForChild(child)
  if (!lastObs || !lastObs.dateOfAssessment) return false
  if (dayjs().diff(lastObs.dateOfAssessment, "month") < 1) return false
  const lastScreening = await getLastSavedFeedingScreeningForChild(child)
  if (lastScreening && lastScreening.dateOfAssessment && lastScreening.dateOfAssessment > lastObs.dateOfAssessment) return false
  const lastObsDate = dayjs(lastObs.dateOfAssessment)
  const dob = dayjs(child.dob)
  const today = dayjs()
  const milestoneDates = FEEDING_MILESTONES.map(months => dob.add(months, "month"))
  const intermediateMilestones = milestoneDates.filter(date => date > lastObsDate && date <= today)
  return intermediateMilestones.length > 0
}

export async function setChildToDoFeedingScreeningInstead(child: Child): Promise<void> {
  child.nextMealtimeAssessmentType = "screening"
  await db.transaction("rw", db.children, db.pendingUploads, async () => {
    return Promise.all([
      // (update only touches the fields that are provided)
      db.children.update(child.id, { nextMealtimeAssessmentType: child.nextMealtimeAssessmentType }),
      queuePendingUpload({ type: "child", localItemId: child.id })
    ])
  })

}
